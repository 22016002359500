export const ImageObjectFit = React.forwardRef(ActualImageObjectFit)
function ActualImageObjectFit({ src, alt, objectFit = 'cover', layoutClassName = 'undefined' }, ref) {
  const [supportsObjectFit, setSupportsObjectFit] = React.useState(true)
  React.useEffect(
    () => { if ('objectFit' in document.documentElement.style === false) setSupportsObjectFit(false) },
    []
  )

  return supportsObjectFit
    ? <Image {...{ ref, src, alt, objectFit, layoutClassName }} />
    : <BackgroundImage {...{ ref, src, alt, objectFit, layoutClassName }} />
}

const Image = React.forwardRef(ActualImage)
function ActualImage({ src, alt, objectFit, layoutClassName }, ref) {
  return <img {...{ ref, src, alt }} className={layoutClassName} style={{ objectFit }} />
}

const BackgroundImage = React.forwardRef(ActualBackgroundImage)
function ActualBackgroundImage({ src, alt, objectFit, layoutClassName }, ref) {
  return (
    <div
      aria-label={alt}
      className={layoutClassName}
      role='img'
      style={{
        backgroundImage: `url(${src})`,
        backgroundPosition: 'center',
        backgroundSize: objectFit
      }}
      {...{ ref }}
    />
  )
}
