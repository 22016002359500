import styles from './Icon.css'

export function Icon({ icon, layoutClassName = undefined }) {
  return (
    <span
      className={cx(styles.component, layoutClassName)}
      role='presentation'
      dangerouslySetInnerHTML={{ __html: icon }}
    />
  )
}

export function IconPlay() {
  return <Svg size={20}><path fill="currentColor" d="M2 0l15.607 10.263L2 20.526z" /></Svg>
}

export function IconPause() {
  return <Svg size={20}><path d="M3 0h4v20H3zM13 0h4v20h-4z" /></Svg>
}

export function IconLeftArrow() {
  return (
    <Svg size={73}>
      <path fill="currentColor" d="M18.5688674,0 L30.6630888,12.8272045 L18.5688674,25.6544091 L15.6585097,22.9103576 L23.278,14.827 L0,14.8272045 L0,10.8272045 L23.278,10.827 L15.6585097,2.74405147 L18.5688674,0 Z" transform="matrix(-1 0 0 1 50.914 24.173)" />
    </Svg>
  )
}

export function IconRightArrow() {
  return (
    <Svg size={73}>
      <path fill="currentColor" d="M18.5688674,0 L30.6630888,12.8272045 L18.5688674,25.6544091 L15.6585097,22.9103576 L23.278,14.827 L0,14.8272045 L0,10.8272045 L23.278,10.827 L15.6585097,2.74405147 L18.5688674,0 Z" transform="matrix(1 0 0 -1 20.251 49.827)" />
    </Svg>
  )
}

export function IconCross() {
  return (
    <Svg size={36}>
      <path d="M35.633 5.81 23.568 17.875 35.633 29.94l-5.568 5.568L18 23.443 5.935 35.508.367 29.94l12.065-12.065L.367 5.81 5.935.242 18 12.307 30.065.242z" />
    </Svg>
  )
}

export function IconMenu() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="20" viewBox="0 0 32 20">
      <path d="M0,0 L32,0 L32,6 L0,6 L0,0 Z M0,14 L32,14 L32,20 L0,20 L0,14 Z" />
    </svg>
  )
}

export function IconPlus() {
  return (
    <svg width="32" viewBox="0 0 32 32" height="32" xmlns="http://www.w3.org/2000/svg">
      <g fill="currentColor" fillRule="evenodd">
        <path d="M14 9h3v14h-3z" fill='#fff' />
        <path d="M22.5 14.5v3h-14v-3z" fill='#fff' />
      </g>
    </svg>
  )
}

function Svg({ children, size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      xmlns='http://www.w3.org/2000/svg'
    >
      {children}
    </svg>
  )
}
