import { useLocation } from '@reach/router'
import { parse } from 'qs'
import { matchRoute } from '/routes'
import { reportError } from '/machinery/reportError'
import { useSanityClient } from '/machinery/SanityClient'
import { useIsPreview } from './useIsPreview'

const PageRouteDataContext = React.createContext(null)

export function PageRouteDataContextProvider({ children, data, status }) {
  const pageRouteData = useFetchPageRouteData({ data, status })
  return <PageRouteDataContext.Provider value={pageRouteData} {...{ children }} />
}

export function usePageRouteData() {
  const pageRouteData = React.useContext(PageRouteDataContext)

  if (!pageRouteData) {
    throw new Error('Please make sure PageRouteDataContextProvider is available')
  }

  return pageRouteData
}

function useFetchPageRouteData(initialPageRouteData) {
  const { sanityClient } = useSanityClient()
  const location = useLocation()
  const [state, dispatch] = React.useReducer(
    fetchRouteDataReducer,
    fetchRouteDataReducer.initialState(initialPageRouteData)
  )
  const historyStateRef = React.useRef({ [location.key]: initialPageRouteData })
  const isPreview = useIsPreview()

  React.useMemo(
    () => {
      let valid = true
      const { pathname, search, key } = location
      const url = [pathname, search].filter(Boolean).join('')
      const match = matchRoute(url)

      if (match) resolve(match, { isPreview })
      else dispatch({ type: 'setData', status: 404, data: null })

      return () => { valid = false }

      async function resolve({ route, params }, { isPreview }) {
        try {
          if (!valid) return

          // Check if we can use a cached version of page route data for this key
          const cachedPageRouteData = historyStateRef.current[key]
          if (!cachedPageRouteData) dispatch({ type: 'loading' })

          const { status, data } = (!isPreview && cachedPageRouteData)
            ? cachedPageRouteData
            : (
              route.fetchData
                ? await route.fetchData(sanityClient, { params, query: parse(search.slice(1)), isPreview })
                : { status: 200, data: null }
            )

          if (!valid) return

          historyStateRef.current[key] = { status, data }
          dispatch({ type: 'setData', status, data })
        } catch (err) {
          reportError(err)
          dispatch({ type: 'error' })
        }
      }
    },
    [isPreview, sanityClient, location]
  )

  return state
}

fetchRouteDataReducer.initialState = function({ data, status }) {
  return { data, status, isError: false, isLoading: false }
}

function fetchRouteDataReducer(state, action) {
  switch (action.type) {
    case 'error':
      return {
        data: null,
        status: action.status,
        isError: true,
        isLoading: false
      }
    case 'loading':
      // When loading, the data is set to null. This prevents the old data
      // from being rendered in the new page component.
      return {
        data: null,
        status: action.status,
        isError: false,
        isLoading: true
      }
    case 'setData':
      return {
        data: action.data,
        status: action.status,
        isError: false,
        isLoading: false
      }
    default:
      reportError(`Invalid action type (${action.type})`)
      if (process.env.NODE_ENV !== 'production') {
        console.info(`Valid action types are 'error', 'loading' and 'setData'`)
      }

      throw new Error()
  }
}
