import { useViewport } from '/machinery/Viewport'
import {
  SetSiteHeaderColorToWhite, SetSiteHeaderColorToBlack, SetSiteHeaderColorToWhiteRef, SetSiteHeaderColorToBlackRef
} from '/components/buildingBlocks/SetSiteHeaderColorTo'
import { validateSrcs } from '/machinery/validateSrcs'
import { useSanityClient } from '/machinery/SanityClient'

import mediaStyles from '/cssGlobal/media.css'
import containerStyles from '/cssGlobal/container.css'
import styles from './Quote.css'

const sizes = {
  XS: { width: parseInt(mediaStyles['breakpoint-md'], 10), height: 300 },
  MD: { width: parseInt(containerStyles['container-lg'], 10) / 2, height: 700 }
}

export function QuoteFromSanity({ isDark, backgroundColor, quote, cite, image, isDarkImage }) {
  const { viewportMd } = useViewport()
  const { imageBuilder } = useSanityClient()
  const sizeID = viewportMd ? 'MD' : 'XS'
  const src = image && image.asset && imageBuilder.image(image).width(sizes[sizeID].width).height(sizes[sizeID].height).url()
  console.log(sizes)

  return <QuoteBase alt={image.alt} {...{ backgroundColor, isDark, quote, cite, src, isDarkImage }} />
}

// Candidate for deletion
export function Quote({ backgroundColor, isDark = false, srcs, alt, quote, cite, isDarkImage = true }) {
  const { viewportMd } = useViewport()
  validateSrcs(srcs, sizes)
  return (
    <QuoteBase
      src={viewportMd ? srcs.MD : srcs.XS}
      {...{ alt, backgroundColor, isDark, quote, cite, isDarkImage }}
    />
  )
}

function QuoteBase({ backgroundColor, isDark, cite, quote, alt, src, isDarkImage }) {
  const imageRef = React.useRef(null)
  const Container = isDark ? SetSiteHeaderColorToWhite : SetSiteHeaderColorToBlack
  const ImageContainer = isDarkImage ? SetSiteHeaderColorToWhiteRef : SetSiteHeaderColorToBlackRef

  return (
    <Container>
      <article className={styles.componentBase} style={{ backgroundColor }}>
        <SetSiteHeaderColorToBlack layoutClassName={styles.whiteStart}>
          <div className={styles.white} />
        </SetSiteHeaderColorToBlack>
        <div className={styles.blockquoteWithCite}>
          <figure className={styles.figure} style={{ color: isDark ? 'white' : 'black' }}>
            <blockquote className={styles.blockquote}>
              <p className={styles.quoteText}>{quote}</p>
            </blockquote>
            <figcaption>
              <cite className={styles.cite}>{cite}</cite>
            </figcaption>
          </figure>
        </div>
        <ImageContainer childRef={imageRef}>
          <div
            aria-label={alt}
            ref={imageRef}
            className={styles.image}
            style={{ backgroundImage: `url(${src})` }}
          />
        </ImageContainer>
        <div className={styles.cover} style={{ backgroundColor }} />
        <SetSiteHeaderColorToBlack layoutClassName={styles.whiteEnd}>
          <div className={styles.white} />
        </SetSiteHeaderColorToBlack>
      </article>
    </Container>
  )
}
