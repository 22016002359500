import cx from 'classnames'
import styles from './Section.css'
import { SetSiteHeaderColorToWhite, SetSiteHeaderColorToBlack } from '/components/buildingBlocks/SetSiteHeaderColorTo'
import { CenteredContainer } from '/components/pageOnly/buildingBlocks/CenteredContainer'
import { useViewport } from '/machinery/Viewport'

export { HeadingMd as SectionHeading } from '/components/buildingBlocks/Heading'
export { LinkText as SectionLink } from '/components/buildingBlocks/Link'

export function Section({ backgroundColor = null, color = null, children, height, layoutClassName = undefined }) {
  return <SectionBase {...{ backgroundColor, color, children, height, layoutClassName }} />
}

export function SectionImage({ srcs, alt, width, imageIsLight = false, backgroundColor = null }) {
  const { size } = useViewport()
  const Container = imageIsLight ? SetSiteHeaderColorToBlack : SetSiteHeaderColorToWhite
  return (
    <SectionBase height={backgroundColor ? 'md' : 'sm'} {...{ backgroundColor }}>
      <CenteredContainer {...{ width }}>
        <Container>
          <div className={styles.componentImage}>
            <img src={srcs[size]} className={styles.image} {...{ alt }} />
          </div>
        </Container>
      </CenteredContainer>
    </SectionBase>
  )
}

export function SectionWithOffset({ backgroundColor = null, color = null, children, height }) {
  return <SectionBase {...{ backgroundColor, color, children, height }} className={styles.componentWithOffset} />
}

export function SectionWithQuote({ children }) {
  return <section className={styles.componentWithQuote}>{children}</section>
}

function SectionBase({ backgroundColor, color = null, children, className = null, height, layoutClassName = undefined }) {
  return (
    <section style={{ backgroundColor, color }} className={cx(styles.componentBase, styles[height], className, layoutClassName)}>
      {children}
    </section>
  )
}
