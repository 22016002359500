import groq from 'groq'

import { useSetDocumentTitle } from '/machinery/useSetDocumentTitle'
import { usePageRouteData } from '/machinery/PageRouteData'
import { getSingletonTypeById } from '/machinery/groqSnippets'

import { PageLayout } from '/components/articles/pageOnly/PageLayout'
import { Header as Hero } from '/components/articles/pageOnly/Header'
import { SetSiteHeaderColorToWhite } from '/components/buildingBlocks/SetSiteHeaderColorTo'
import { SliderStack } from '/components/pageOnly/SliderStack'
import { Cta } from  '/components/pageOnly/Cta'
import { Clients } from '/components/inventors/pageOnly/Clients'
import { OntdekkingsDrift } from '/components/inventors/pageOnly/OntdekkingsDrift'
import { DoubleDiamond } from '/components/inventors/pageOnly/DoubleDiamond'
import { Intro, IntroLink } from '/components/home/pageOnly/Intro'
import { introGifAssets } from '/components/home/assets/introGifAssets'
import { ContentContainer } from '/components/home/pageOnly/ContentContainer'

import styles from './Inventors.css'

import centraalMuseum from '/components/inventors/assets/clients/centraalMuseum.svg'
import deMolen from '/components/inventors/assets/clients/deMolen.svg'
import dji from '/components/inventors/assets/clients/dji.svg'
import klm from '/components/inventors/assets/clients/klm.svg'
import npo from '/components/inventors/assets/clients/npo.svg'
import vodafoneZiggo from '/components/inventors/assets/clients/vodafoneZiggo.svg'

const clients = [
  { name: 'DJI', logoSrc: dji },
  { name: 'VodafoneZiggo',  logoSrc: vodafoneZiggo },
  { name: 'Centraal Museum', logoSrc: centraalMuseum },
  { name: 'KLM', logoSrc: klm },
  { name: 'NPO',  logoSrc: npo },
  { name: 'De Molen', logoSrc: deMolen }
]

const query = groq`
  ${getSingletonTypeById('inventors')} {
    title,
    sliderStack {
      ...,
      slides[] {
        ...,
        link {
          ...
          slug,
          ref->{
            _type, slug { current }, _id
          },
        }
      }
    },
  }
`

Inventors.meta = {
  title: 'Inventors',
  description: '',
  async fetchData(sanityClient, { isPreview = false }) {
    return {
      data: await sanityClient.fetch(query, { isPreview }),
      status: 200
    }
  }
}

export function Inventors() {
  const { isLoading, isError, data } = usePageRouteData()
  useSetDocumentTitle(Inventors.meta.title)

  return (
    <>
      {isLoading && <LoadingStatus />}
      {isError && <ErrorStatus />}
      {<Page {...{ data }} />}
    </>
  )
}

function Page({ data }) {
  const { title, sliderStack } = data || {}

  return (
    <PageLayout>
      <div className={styles.componentPage}>
        <Hero layoutClassName={styles.heroLayout} {...{ title }}>
          Kaliber Inventors vindt oplossingen voor vraagstukken waarvan je niet zo goed weet wat je ermee aan moet. Door er nét iets anders naar te kijken en zonder angst met innovatief gereedschap aan de slag te gaan.
        </Hero>
        <SetSiteHeaderColorToWhite>
          {sliderStack?.slides && (
            <SliderStack
              slides={sliderStack.slides}
              layoutClassName={styles.sliderLayout}
            />
          )}
        </SetSiteHeaderColorToWhite>
        <TextBlock />
        <Clients {...{ clients }} />
        <DoubleDiamond />
        <SetSiteHeaderColorToWhite >
          <OntdekkingsDrift />
        </SetSiteHeaderColorToWhite>
        <Cta text='Ook een vraagstuk dat vraagt om een innovatieve benadering? Neem contact met ons op om de mogelijkheden te ontdekken. ' />
      </div>
    </PageLayout>
  )
}

function LoadingStatus() {
  return <p>Laden...</p>
}

function ErrorStatus() {
  return <p>Er is een fout opgetreden</p>
}


function TextBlock() {
  return (
    <ContentContainer width='lg'>
      <div className={styles.componentTextBlock}>
        <Intro>
          <p>
            Nieuwe tijden brengen nieuwe vraagstukken. Die vragen om een tegendraadse benadering en innovatief denken. Kaliber Inventors kleurt buiten de lijntjes en zoekt naar een verrassende invalshoeken, met de inzet van nieuwe technologie, kunstmatige intelligentie of juist fysieke interactie.
          </p>
          <p>
            Dat kan van alles opleveren: een <IntroLink src={introGifAssets.pom}>voice activated praatmaatje</IntroLink> voor kinderen met een taalachterstand, een <IntroLink src={introGifAssets.breinreis}>kunstinstallatie</IntroLink> die je aanstuurt met je hersengolven, <IntroLink src={introGifAssets.dji}>interactieve tafels</IntroLink> voor jeugdgevangenissen, een  <IntroLink src={introGifAssets.velux}>VR installatie</IntroLink> om licht en lucht te ontdekken of een <IntroLink src={introGifAssets.vz}>geluidsdouche</IntroLink> om een campagne kracht bij te zetten.
          </p>
        </Intro>
      </div>
    </ContentContainer>
  )
}


