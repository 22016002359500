import { useSetDocumentTitle } from '/machinery/useSetDocumentTitle'
import { usePageRouteData } from '/machinery/PageRouteData'
import { routes } from '/routes'

import { ModalNew } from '/components/experiments/ModalNew'
import { PageLayout } from '/components/articles/pageOnly/PageLayout'
import { Header } from '/components/articles/pageOnly/Header'
import { ContainerLight } from '/components/home/Container'
import { Section } from '/components/home/pageOnly/Section'
import { CenteredContainer } from '/components/pageOnly/buildingBlocks/CenteredContainer'
import { GridAndCards } from '/components/experiments/GridAndCards'
import { LinkTextUnderline } from '/components/buildingBlocks/Link'

import styles from './Experiments.css'

const query = `*[_type == "experiment"] | order(documentOrder) {
  _id,
  title,
  mainImage,
  flex,
  tags,
  sources,
  body,
  flexibleContent,
  showVideo
}
`

Experiments.meta = {
  title: 'Experimenten - een greep uit wat ons dagelijks bezighoudt',
  description: 'We willen niet alleen weten wat er in de snel veranderende wereld kan, we willen het ook begrijpen. Dus waar wij kansen zien experimenteren wij.',
  async fetchData(sanityClient) {
    const rawEntries = await sanityClient.fetch(query)
    const entries = rawEntries && rawEntries.map(({ mainImage, ...rest }) => ({
      media: {
        src: mainImage,
      },
      ...rest
    }))

    return {
      data: { entries },
      status: 200
    }
  }
}

export function Experiments() {
  const { isLoading, isError, data } = usePageRouteData()
  useSetDocumentTitle(Experiments.meta.title)

  return (
    <>
      {isLoading && <LoadingStatus />}
      {isError && <ErrorStatus />}
      {data && <Page {...{ data }} />}
    </>
  )
}

function Page({ data  }) {
  const [isOpen, setIsOpen] = React.useState(false)
  const [activeItem, setActiveItem] = React.useState(undefined)
  const { entries = [] } = data || {}

  return (
    <PageLayout>
      <div className={styles.componentPage}>
        <Header title='Experimenten'>
          We experimenteren graag. Dat levert zowel briljante als op het oog zinloze uitvindingen op. Maar het zorgt sowieso voor nieuwe inzichten die we gebruiken voor onze serieuze projecten.
          Samen experimenteren? <LinkTextUnderline to={routes.inventors.path}>Ontdek meer over onze innovatieprojecten</LinkTextUnderline>.
        </Header>
        <ContainerLight>
          <Section backgroundColor='white' color='black'>
            <CenteredContainer width='xl'>
              <GridAndCards items={entries} onOpenChange={handleOpenChange} />
            </CenteredContainer>
          </Section>
        </ContainerLight>
        {activeItem && (
          <ModalNew
            item={activeItem}
            onActiveChange={handleCloseChange}
            onClose={() => setActiveItem(null)}
            visible={isOpen}
            layoutClassName={styles.modalLayout}
          />
        )}
      </div>
    </PageLayout>
  )

  function handleOpenChange(x) {
    setIsOpen(true)
    setActiveItem(x)
  }

  function handleCloseChange() {
    setIsOpen(false)
    setActiveItem(null)
  }
}

function LoadingStatus() {
  return <p>Laden...</p>
}

function ErrorStatus() {
  return <p>Er is een fout opgetreden</p>
}
